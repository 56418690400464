var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_vm._m(0),_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 15 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('CardHeader',{attrs:{"icon":_vm.icon1,"title":"基本信息"}}),_c('a-row',[_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'name',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]),expression:"[\n                'name',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入！',\n                    },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"性别"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'sex',
                {
                  rules: [
                    {
                      required: true,
                      message: '请选择！',
                    },
                  ],
                },
              ]),expression:"[\n                'sex',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请选择！',\n                    },\n                  ],\n                },\n              ]"}]},_vm._l(([{
              name: '男',
              value: 'Male'
            }, {
              name: '女',
              value: 'FeMale'
            }]),function(item){return _c('a-radio',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"出生日期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'birthday',
                {
                  rules: [
                    {
                      required: true,
                      message: '请选择！',
                    },
                  ],
                },
              ]),expression:"[\n                'birthday',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请选择！',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"inputReadOnly":true,"placeholder":""}})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"身份证号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'idCard',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]),expression:"[\n                'idCard',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入！',\n                    },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"国籍"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'nationality',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]),expression:"[\n                'nationality',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入！',\n                    },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"籍贯"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'nativePlace',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]),expression:"[\n                'nativePlace',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入！',\n                    },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"民族"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'ethnicGroup',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]),expression:"[\n                'ethnicGroup',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入！',\n                    },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"身高(cm)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'height',
              ]),expression:"[\n                'height',\n              ]"}],staticStyle:{"width":"100%"}})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"体重(kg)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'weight',
              ]),expression:"[\n                'weight',\n              ]"}],staticStyle:{"width":"100%"}})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"兴趣爱好"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'interests',
              ]),expression:"[\n                'interests',\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"专业特长"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'expertises',
              ]),expression:"[\n                'expertises',\n              ]"}]})],1)],1)],1),_c('CardHeader',{attrs:{"icon":_vm.icon4,"title":"联系信息"}}),_c('a-row',[_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"手机号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'mobile',
                {

                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]),expression:"[\n                'mobile',\n                {\n\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入！',\n                    },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"个人邮箱"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'emailIndividual',
             {

                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]),expression:"[\n                'emailIndividual',\n             {\n\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入！',\n                    },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"通讯地址"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'address',
     {

                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]),expression:"[\n                'address',\n     {\n\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入！',\n                    },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"紧急联系人"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'emergencyContact',
                {

                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]),expression:"[\n                'emergencyContact',\n                {\n\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入！',\n                    },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":8,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"紧急联系人电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'emergencyContactMobile',
                {

                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]),expression:"[\n                'emergencyContactMobile',\n                {\n\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入！',\n                    },\n                  ],\n                },\n              ]"}]})],1)],1)],1),_c('div',{staticClass:"center"},[_c('a-button',{attrs:{"loading":_vm.saveLoading,"htmlType":"submit","type":"primary"}},[_vm._v("保存")])],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/small-logo.png"),"alt":"logo"}}),_c('div',{staticClass:"title"},[_c('div',[_vm._v("南京市市政设计研究院有限责任公司")]),_c('div',[_vm._v("新员工注册")])])])
}]

export { render, staticRenderFns }